import { useSelector, useDispatch } from "react-redux";
import {
  showLoader,
  closeLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";
import { useState } from "react";
import {
  NameContainer,
  NoteContainer,
  SingleImageAdd,
  SubmitContainer,
} from "../sharedComponents";
import { createCatagory } from "../../../../services/menuServices";
import Cookies from "universal-cookie";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { fetchMenu } from "../../../../redux/menuSlice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export default function AddNewCatagory() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { t } = useTranslation();

  const [newImage, setNewImage] = useState();
  let [newData, setNewData] = useState({
    name: { en: "", ar: "", ku: "", tr: "" },
    note: { en: "", ar: "", ku: "", tr: "" },
  });

  const { menu } = useSelector((state) => state.menu);
  const languages = menu.langs.map((e) => e.surname);
  const id = menu.menu_id;

  async function handleSubmit(e) {
    e.preventDefault();

    dispatch(showLoader());
    let formData = new FormData();
    const token = cookies.get("token");

    const validData = {
      menu_id: id,

      title: "",

      name_en: newData.name.en,
      name_ar: newData.name.ar,
      name_ku: newData.name.ku,
      name_tr: newData.name.tr,

      note_en: newData.note.en,
      note_ar: newData.note.ar,
      note_ku: newData.note.ku,
      note_tr: newData.note.tr,

      image: newImage,
    };

    Object.keys(validData).forEach((e) => {
      formData.append(e.toString(), validData[e]);
    });

    await createCatagory(formData, token);
    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <NameContainer
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      <NoteContainer
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      <SingleImageAdd newImage={newImage} setNewImage={setNewImage} t={t} />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}
