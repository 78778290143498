import Cookies from "universal-cookie";
import { addSlideShow } from "../../services/menuServices";
import { SlideShowContainerEdit } from "./sharedMenuComponents";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getMenus } from "../../redux/getMenus/getMenus";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export default function EditSlideShow({ menuInfo }) {
  const [hasSlideShow, setHasSlideShow] = useState(menuInfo.have_slider);
  const [slideShowImages, setSlideShowImages] = useState([]);

  const serverImages = getslides();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  function getslides() {
    const arrayOfImages = [];
    for (var i = 1; i <= menuInfo.num_slides; i++) {
      arrayOfImages.push(
        `https://menuapi.mubatech.com/images/sliders/${menuInfo.title}-0${i}.jpg`
      );
    }

    return arrayOfImages;
  }

  async function handleAddSlideShow() {
    const token = cookies.get("token");
    const userId = cookies.get("userID");

    const formdata = new FormData();
    formdata.append("image", slideShowImages[0]);
    formdata.append("title", menuInfo.title);

    await addSlideShow(formdata, token);
    dispatch(getMenus(userId));
    setSlideShowImages([]);
  }

  function resetData() {
    setHasSlideShow(menuInfo.have_slider);
    setSlideShowImages(getslides());
  }

  return (
    <div className="position-relative">
      <SlideShowContainerEdit
        hasSlideShow={hasSlideShow}
        setHasSlideShow={setHasSlideShow}
        serverImages={serverImages}
        slideShowImages={slideShowImages}
        setSlideShowImages={setSlideShowImages}
        t={t}
      />
      <div className="my-2">
        <button
          disabled={slideShowImages.length === 0}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            handleAddSlideShow();
          }}
        >
          {t("submit")} {t("image")}
        </button>
      </div>
    </div>
  );
}
