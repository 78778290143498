import { useDispatch, useSelector } from "react-redux";
import {
  ActiveSwitch,
  ChangeRank,
  DeleteContainer,
  DoubleImage,
  DoubleImageContainer,
  Dublicate,
  Move,
  NameContainerEdit,
  NoteContainerEdit,
  PriceContainerEdit,
  SubmitContainer,
  Swtiches,
} from "../sharedComponents";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  DeleteItem,
  createThumbnail,
  isActiveItem,
  updateData,
  updateImage,
} from "../../../../services/menuServices";
import {
  closeLoader,
  showLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";
import { useParams } from "react-router-dom";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { fetchMenu } from "../../../../redux/menuSlice";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();
let token = cookies.get("token");

export default function EditItem() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { t } = useTranslation();

  let { menu } = useSelector((state) => state.menu);
  let { data } = useSelector((state) => state.menuFunction);
  let languages = menu.langs.map((e) => e.surname);

  let [newImage, setNewImage] = useState();
  let [isSub, setIsSub] = useState(data.have_sub);
  let [isMultiPrice, setIsMultiplePrices] = useState(data.have_price);
  let [currentTab, setCurrentTab] = useState(0);

  const [newData, setNewData] = useState({
    id: data.id,
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: { en: data.en, ar: data.ar, ku: data.ku, tr: data.tr },
    price: data.price,
    prices: data.item_price,
  });

  function handleActiveSwitch(e) {
    const token = cookies.get("token");

    isActiveItem(data.id, e, token);
  }

  useEffect(() => {
    setNewImage();
    setIsSub(data.have_sub);
    setIsMultiplePrices(data.have_price);
    setCurrentTab(0);
    setNewData({
      id: data.id,
      name: {
        en: data.name.en,
        ar: data.name.ar,
        ku: data.name.ku,
        tr: data.name.tr,
      },
      note: { en: data.en, ar: data.ar, ku: data.ku, tr: data.tr },
      price: data.price,
      prices: data.item_price,
    });
  }, [data]);

  const oldData = {
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: { en: data.en, ar: data.ar, ku: data.ku, tr: data.tr },
    price: data.price,
    have_price: data.have_price,
    have_sub: data.have_sub,
    prices: data.item_price,
  };

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(showLoader());
    let formData = new FormData();

    let validData = {
      id: newData.id,
      price: isMultiPrice ? 0 : newData.price,
      prices: isMultiPrice ? JSON.stringify(newData.prices) : [],
      have_price: isMultiPrice ? "true" : "false",
      have_sub: isSub ? "true" : "false",
      en: newData.name.en,
      ar: newData.name.ar,
      ku: newData.name.ku,
      tr: newData.name.tr,
      note_en: newData.note.en,
      note_ar: newData.note.ar,
      note_ku: newData.note.ku,
      note_tr: newData.note.tr,
    };

    Object.keys(validData).forEach((objName) => {
      formData.append(objName.toString(), validData[objName]);
    });
    formData.append("type", "item");

    console.log(validData);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    await updateData(formData, token);
    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  async function submitImage(e) {
    e.preventDefault();
    dispatch(showLoader());
    let imageFormData = new FormData();

    imageFormData.append("image", newImage);
    imageFormData.append("type", "item");
    imageFormData.append("id", newData.id);

    await updateImage(imageFormData, token);
    await createThumbnail(imageFormData, token);
    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  async function handleDelete(e) {
    e.preventDefault();
    dispatch(showLoader());

    await DeleteItem(data.id, "item", token);

    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  let myTabs = [
    <DataChange
      handleSubmit={handleSubmit}
      oldData={oldData}
      languages={languages}
      newData={newData}
      setNewData={setNewData}
      isSub={isSub}
      isMultiPrice={isMultiPrice}
      setIsMultiplePrices={setIsMultiplePrices}
      t={t}
    />,
    <ImageChange
      submitImage={submitImage}
      newImage={newImage}
      setNewImage={setNewImage}
      data={data}
      t={t}
    />,
    <ManageTab
      data={data}
      handleDelete={handleDelete}
      handleActiveSwitch={handleActiveSwitch}
      t={t}
    />,
  ];

  return (
    <div className="">
      <ul className="nav bg-white nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab == 0 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(0)}
          >
            {t("data")}
          </button>
        </li>
        {isSub ? null : (
          <li className="nav-item">
            <button
              className={`nav-link px-2 ${
                currentTab == 1 ? "bg-dark-subtle" : ""
              }`}
              onClick={() => setCurrentTab(1)}
            >
              {t("image")}
            </button>
          </li>
        )}
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab == 2 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(2)}
          >
            {t("manage")}
          </button>
        </li>
      </ul>
      {myTabs[currentTab]}
    </div>
  );
}

function DataChange({
  handleSubmit,
  oldData,
  languages,
  newData,
  setNewData,
  isSub,
  isMultiPrice,
  setIsMultiplePrices,
  t,
}) {
  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <NameContainerEdit
        oldData={oldData}
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      {isSub ? null : (
        <React.Fragment>
          <NoteContainerEdit
            oldData={oldData}
            languages={languages}
            newData={newData}
            setNewData={setNewData}
            t={t}
          />
          <hr />
          <PriceContainerEdit
            oldData={oldData}
            newData={newData}
            setNewData={setNewData}
            languages={languages}
            isMultiPrice={isMultiPrice}
            setIsMultiplePrices={setIsMultiplePrices}
            t={t}
          />
          <hr />
        </React.Fragment>
      )}
      <SubmitContainer t={t} />
    </form>
  );
}

function ImageChange({ submitImage, newImage, setNewImage, data, t }) {
  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        submitImage(e);
      }}
    >
      <DoubleImageContainer
        newImage={newImage}
        setNewImage={setNewImage}
        t={t}
      />
      <DoubleImage
        fullImage={`https://menuapi.mubatech.com/images/items/${data.id}.jpg`}
        thumbnail={`https://menuapi.mubatech.com/images/items/thumbnail/${data.id}.jpg`}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}

function ManageTab({ data, handleDelete, handleActiveSwitch, t }) {
  return (
    <div className="p-3">
      <h4> {t("manage")} </h4>
      <hr />

      <ChangeRank />

      <ActiveSwitch
        defVal={data.enable}
        handleActiveSwitch={handleActiveSwitch}
        t={t}
      />

      <Move />
      <Dublicate />
      <DeleteContainer handleDelete={handleDelete} type="ITEM" t={t} />
    </div>
  );
}
