import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  view: false,
  selectType: "",
  data: "",
};

const menuFunctionsSlicer = createSlice({
  name: "menuFunction",
  initialState,
  reducers: {
    viewEdit: (state, action) => {
      state.view = true;
      state.id = action.payload.id;
      state.data = action.payload.allData;
      state.selectType = action.payload.selectType;
    },
    closeEdit: (state) => {
      state.view = false;
      state.id = "";
      state.selectType = "";
      state.data = "";
    },
  },
});

export default menuFunctionsSlicer.reducer;

export const { viewEdit, closeEdit } = menuFunctionsSlicer.actions;
