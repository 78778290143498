import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import {
  DoubleImageContainer,
  NameContainer,
  NoteContainer,
  PriceContainer,
  SubmitContainer,
} from "../sharedComponents";
import {
  createSubtITem,
  createThumbnail,
} from "../../../../services/menuServices";
import {
  closeLoader,
  showLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { fetchMenu } from "../../../../redux/menuSlice";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export default function AddNewSub() {
  const dispatch = useDispatch();
  const { title } = useParams();
  const { t } = useTranslation();

  let { menu } = useSelector((state) => state.menu);
  let { id } = useSelector((state) => state.menuFunction);
  let languages = menu.langs.map((e) => e.surname);

  const [newImage, setNewImage] = useState();
  const [isMultiPrice, setIsMultiplePrices] = useState(false);
  const [newData, setNewData] = useState({
    name: { en: "", ar: "", ku: "", tr: "" },
    // note: { en: "", ar: "", ku: "", tr: "" },
    price: 0,
    prices: [],
  });

  async function handleSubmit(e) {
    e.preventDefault();
    dispatch(showLoader());
    const formData = new FormData();
    const imageData = new FormData();
    const token = cookies.get("token");

    let validData = {
      item_id: id,
      price: newData.price,
      prices: JSON.stringify(newData.prices),
      have_price: isMultiPrice,
      en: newData.name.en,
      ar: newData.name.ar,
      ku: newData.name.ku,
      tr: newData.name.tr,
      // note_en: newData.note.en,
      // note_ar: newData.note.ar,
      // note_ku: newData.note.ku,
      // note_tr: newData.note.tr,
      image: newImage,
    };

    Object.keys(validData).forEach((e) => {
      formData.append(`${e}`, validData[e]);
    });

    let valid = await createSubtITem(formData, token);

    if (newImage) {
      await imageData.append("id", valid.data.id);
      await imageData.append("type", "sub");
      await imageData.append("image", validData.image);

      await createThumbnail(imageData, token);
    }
    dispatch(closeLoader());
    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  return (
    <form className="p-3" onSubmit={(e) => handleSubmit(e)}>
      <NameContainer
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      {
        //  <NoteContainer
        //   languages={languages}
        //   newData={newData}
        //   setNewData={setNewData}
        // />
        // <hr />
      }
      <DoubleImageContainer
        newImage={newImage}
        setNewImage={setNewImage}
        t={t}
      />
      <hr />
      <PriceContainer
        newData={newData}
        setNewData={setNewData}
        languages={languages}
        isMultiPrice={isMultiPrice}
        setIsMultiplePrices={setIsMultiplePrices}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}
