import Cookies from "universal-cookie";
import Menu from "../components/menu/Menu";
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchMenu, reseatMenuData } from "../redux/menuSlice";
import Edit from "../components/edit/menuItems";
import { Link, useParams } from "react-router-dom";
import goBackArrow from "../images/goBackArrow.svg";
import DataEditContainer from "../components/dataEdit/DataEditContainer";
import { getMenus } from "../redux/getMenus/getMenus";
import { useTranslation } from "react-i18next";
import { setToRandLang } from "../redux/preMenuLang/preMenuLangSlice";

const cookies = new Cookies();

export default function EditMenu() {
  const { loader } = useSelector((state) => state.loader);

  const dispatch = useDispatch();
  const { allMenu, allMenuSuccess } = useSelector((store) => store.userMenus);
  const { menu, success } = useSelector((store) => store.menu);
  const { menuLang } = useSelector((store) => store.preMenuLang);

  const { title } = useParams();
  const titles = allMenuSuccess ? allMenu.map((e) => e.title) : [];
  const userId = cookies.get("userID");

  const canView = titles.includes(title) ? true : false;

  function setMenuLang(e) {
    dispatch(setToRandLang(e));
  }

  useEffect(() => {
    async function fetchData() {
      await dispatch(getMenus(userId));

      await dispatch(fetchMenu(title));
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (success === true) dispatch(setToRandLang(menu.langs[0].surname));
  }, [success]);

  // if (status === true) {
  //   return (
  //     <div>
  //       <LoadingScreen
  //         loading={true}
  //         bgColor="#f1f1f1"
  //         spinnerColor="#9ee5f8"
  //         textColor="#676767"
  //         logoSrc="/logo.png"
  //         text="Welcome To Our Menu Service"
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="position-fixed top-0 bottom-0 end-0 start-0 d-flex flex-column">
      <EditMenuHeader dispatch={dispatch} title={title} />
      {canView ? (
        success === true && allMenuSuccess === true ? (
          <div className="overflow-y-scroll h-100">
            <div className="w-100  mx-auto px-0 h-100">
              <div className="row mx-auto px-0 h-100">
                <div
                  style={{ backgroundColor: menu.colors.primary }}
                  className=" col-lg-4 col-md-12 d-none d-lg-block "
                >
                  <Menu
                    siteData={menu}
                    siteId={title}
                    lang={menuLang}
                    setLang={setMenuLang}
                  />
                </div>

                <SmallScreenMenu
                  menu={menu}
                  title={title}
                  menuLang={menuLang}
                  setMenuLang={setMenuLang}
                  success={success}
                />

                <div className="col-lg-8 col-md-12 bg-body-secondary flex-grow-1 flex-shrink-0">
                  <Edit siteData={menu} lang={menuLang} />
                </div>
              </div>

              <DataEditContainer />
            </div>

            {loader ? (
              <div
                className="position-fixed fixed-top fixed-bottom m-auto"
                style={{ background: "rgba(0, 0, 0, 0.3)" }}
              >
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div
                    class="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  ></div>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <AnimatedLoadingScreen />
        )
      ) : (
        <div>Cant Load</div>
      )}
    </div>
  );
}

function SmallScreenMenu({ menu, title, menuLang, setMenuLang, success }) {
  let [smallMenuDisplay, setSmallMenuDisplay] = useState(false);

  return success ? (
    <React.Fragment>
      <div
        className="d-block d-lg-none d-flex align-items-center py-1 border-bottom"
        style={{
          background: menu.colors.primary,
        }}
      >
        <button
          className="mx-auto btn btn-lg btn-outline-light"
          onClick={() => {
            setSmallMenuDisplay((current) => !current);
          }}
        >
          {smallMenuDisplay ? "Hide Menu" : "Show Menu"}
        </button>
      </div>
      {smallMenuDisplay ? (
        <div
          className="d-block d-lg-none"
          style={{ background: menu.colors.primary }}
        >
          <div className="col-sm-12 d-block ">
            <Menu
              siteData={menu}
              siteId={title}
              lang={menuLang}
              setLang={setMenuLang}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  ) : null;
}

function AnimatedLoadingScreen() {
  function TempMenu() {
    return (
      <div className="py-2 bg-light">
        <div
          className="mb-2"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            alignItems: "center",
          }}
        >
          <div></div>
          <div
            className="custom-gradient-background-animated rounded"
            style={{ justifySelf: "center", width: "64px", height: "65px" }}
          ></div>
          <div
            className="custom-gradient-background-animated rounded"
            style={{ justifySelf: "end", width: "120px", height: "32px" }}
          ></div>
        </div>
        <div
          className="custom-gradient-background-animated w-100 rounded"
          style={{ aspectRatio: "2/1" }}
        ></div>
        <div className="my-3 d-flex gap-4">
          {[[], [], []].map((e) => (
            <div className="">
              <div
                className="custom-gradient-background-animated my-4 mx-auto rounded-2"
                style={{ width: "60px", height: "60px" }}
              ></div>

              <div
                className="custom-gradient-background-animated rounded-1"
                style={{ width: "85px", height: "16px" }}
              ></div>
            </div>
          ))}
        </div>

        <div>
          {[[], [], []].map((e) => (
            <div className="custom-gradient-background-animated my-1 mb-3 rounded-3 p-2 d-flex justify-content-between">
              <div>
                <div
                  className="custom-gradient-background-animated rounded-1 mb-2"
                  style={{ width: "120px", height: "16px" }}
                ></div>
                <div
                  className="custom-gradient-background-animated rounded-1"
                  style={{ width: "80px", height: "16px" }}
                ></div>
              </div>
              <div
                className="custom-gradient-background-animated rounded-2"
                style={{ aspectRatio: "1/1", height: "96px" }}
              ></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function TempEdit() {
    return (
      <div className="py-2">
        <div>
          {[[], [], []].map((e) => (
            <div className="custom-gradient-background-animated rounded mb-3 p-2">
              <div className="d-flex align-items-center gap-3">
                <div
                  className="custom-gradient-background-animated rounded"
                  style={{ width: "50px", height: "50px" }}
                ></div>
                <div
                  className="custom-gradient-background-animated rounded"
                  style={{ width: "120px", height: "20px" }}
                ></div>
              </div>
              <div className="custom-gradient-background-animated"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function TempChange() {
    return (
      <div className="py-2">
        <div>
          {[[], []].map((e) => (
            <div className="mb-4">
              <div
                className="custom-gradient-background-animated mb-2 rounded"
                style={{ width: "70px", height: "30px" }}
              ></div>
              <div>
                {[[], [], []].map((e) => (
                  <div
                    className="custom-gradient-background-animated w-100 mb-2 rounded"
                    style={{ height: "40px" }}
                  ></div>
                ))}
              </div>
            </div>
          ))}
          <div className="d-flex justify-content-between">
            <div
              className="custom-gradient-background-animated rounded"
              style={{ width: "105px", height: "40px" }}
            ></div>
            <div
              className="custom-gradient-background-animated rounded"
              style={{ width: "75px", height: "40px" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="h-100">
      <div className="row h-100 mx-0">
        <div className="col-12 col-lg-4 bg-light">
          <TempMenu />
        </div>
        <div className="col-12 col-lg-4 bg-dark-subtle">
          <TempEdit />
        </div>
        <div className="col-12 col-lg-4 bg-light">
          <TempChange />
        </div>
      </div>
    </div>
  );
}

function EditMenuHeader({ dispatch, title }) {
  const { t } = useTranslation();

  const headerToolTipRef = useRef();
  const [headerToolTipView, setHeaderToolTipView] = useState(false);

  return (
    <header className="d-flex p-4 bg-light border-bottom justify-content-between">
      <Link
        className="link-body-emphasis link-underline-opacity-0 py-2 my-auto d-flex "
        to="/"
        style={{ width: "max-content" }}
        onClick={() => {
          dispatch(reseatMenuData());
        }}
      >
        <img className="" src={goBackArrow} style={{ width: "1rem" }} alt="<" />
        <span className="ms-2 col ms-2 fw-bold d-none d-md-block">
          {t("backtoMenus")}
        </span>
      </Link>

      <div className="text-center align-self-center">
        <h2 className="fs-md-6"> {title}</h2>
      </div>
      <div className="position-relative my-auto" ref={headerToolTipRef}>
        <button
          className="btn btn-primary my-auto"
          onClick={() => setHeaderToolTipView(!headerToolTipView)}
        >
          {t("settings")}
        </button>
        <HeaderToolTip
          headerToolTipRef={headerToolTipRef}
          setHeaderToolTipView={setHeaderToolTipView}
          headerToolTipView={headerToolTipView}
          t={t}
        />
      </div>
    </header>
  );
}

function HeaderToolTip({
  headerToolTipRef,
  setHeaderToolTipView,
  headerToolTipView,

  t,
}) {
  const { i18n } = useTranslation();
  const [viewLangList, setViewLangList] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [headerToolTipRef]);

  function handleClickOutside(event) {
    if (
      headerToolTipRef.current &&
      !headerToolTipRef.current.contains(event.target)
    ) {
      setHeaderToolTipView(false);
      setViewLangList(false);
    }
  }

  function logOut() {
    cookies.set("isLogined", false);
    cookies.remove("token");
    cookies.remove("userID");

    sessionStorage.removeItem("isLogined");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userID");

    window.location.reload(true);
  }
  const languageList = {
    en: { nickName: "English" },
    ar: { nickName: "Arabic" },
  };

  return headerToolTipView ? (
    <div className="position-absolute z-3 end-50 mt-1 d-flex gap-1">
      {viewLangList ? (
        <div
          className="bg-light border border-dark-subtle rounded-2 py-2"
          style={{ height: "min-content" }}
        >
          {Object.keys(languageList).map((indLang) => (
            <button
              className={`btn btn-light btn-sm w-100 px-2 rounded-0 fw-semibold ${
                indLang === i18n.resolvedLanguage
                  ? "text-primary"
                  : "text-dark-emphasis"
              }`}
              onClick={() => {
                i18n.changeLanguage(indLang);
                setHeaderToolTipView(false);
                setViewLangList(false);
              }}
            >
              {t(languageList[indLang].nickName)}
            </button>
          ))}
        </div>
      ) : null}
      <div className="bg-light border border-dark-subtle rounded-2 py-2">
        <button
          className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
          onClick={() => setViewLangList(!viewLangList)}
        >
          {t("changeLang")}
        </button>

        <button
          className="btn btn-sm btn-light text-danger text-bold text-nowrap w-100 rounded-0"
          onClick={logOut}
          type="button"
          name=""
          id=""
        >
          {t("logOut")}
        </button>
      </div>
    </div>
  ) : null;
}
