import React, { useState } from "react";
import {
  DoubleImageContainer,
  LangsSwitch,
  ColorsContainer,
  SocialContainer,
  getNormalFont,
} from "./sharedMenuComponents";
import {
  DeleteItem,
  updateData,
  updateImage,
} from "../../services/menuServices";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { getMenus } from "../../redux/getMenus/getMenus";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import EditSlideShow from "./EditSlideShow";
const cookies = new Cookies();

export default function EditMenuInfo({
  menuInfo,
  showEditMenu,
  setShowEditMenu,
  isActive,
  handleOnSwtich,
}) {
  const dispatch = useDispatch();
  const userId = cookies.get("userID");
  const token = cookies.get("token");

  const { t } = useTranslation();

  const [delConfirm, setDelConfirm] = useState(false);
  const [image, setImage] = useState();
  const [selectTab, setSelectTab] = useState(0);

  const [social, setSocial] = useState({
    phone: menuInfo.phone,
    address: menuInfo.address,
    youtube: menuInfo.youtube,
    insta: menuInfo.insta,
    snap: menuInfo.snap,
    fb: menuInfo.fb,
  });

  const [langs, setLangs] = useState({
    en: !!menuInfo.languages.find((lang) => lang.surname === "en"),
    ku: !!menuInfo.languages.find((lang) => lang.surname === "ku"),
    ar: !!menuInfo.languages.find((lang) => lang.surname === "ar"),
    tr: !!menuInfo.languages.find((lang) => lang.surname === "tr"),
  });

  const [colors, setColors] = useState({
    primary: menuInfo.colors.map((e) => e.primary)[0],
    secondary: menuInfo.colors.map((e) => e.secondary)[0],
    primary_font: menuInfo.colors.map((e) => e.primary_font)[0],
    secondary_font: menuInfo.colors.map((e) => e.secondary_font)[0],
  });

  function reSetData() {
    setImage();

    setSocial({
      phone: menuInfo.phone,
      address: menuInfo.address,
      youtube: menuInfo.youtube,
      insta: menuInfo.insta,
      snap: menuInfo.snap,
      fb: menuInfo.fb,
    });
    setLangs({
      en: !!menuInfo.languages.find((lang) => lang.surname === "en"),
      ku: !!menuInfo.languages.find((lang) => lang.surname === "ku"),
      ar: !!menuInfo.languages.find((lang) => lang.surname === "ar"),
      tr: !!menuInfo.languages.find((lang) => lang.surname === "tr"),
    });
    setColors({
      primary: menuInfo.colors.map((e) => e.primary)[0],
      secondary: menuInfo.colors.map((e) => e.secondary)[0],
      primary_font: menuInfo.colors.map((e) => e.primary_font)[0],
      secondary_font: menuInfo.colors.map((e) => e.secondary_font)[0],
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    let formData = new FormData();

    const validData = {
      id: menuInfo.id,

      title: menuInfo.title,

      active: menuInfo.active,

      en: langs.en,
      ku: langs.ku,
      ar: langs.ar,
      tr: langs.tr,

      youtube: social.youtube,
      insta: social.insta,
      snap: social.snap,
      fb: social.fb,
      phone: social.phone,
      address: social.address,

      primary: colors.primary,
      secondary: colors.secondary,
      primary_font: colors.primary_font,
      secondary_font: colors.secondary_font,

      normal_font: getNormalFont(colors.primary),
    };

    Object.keys(validData).forEach((e) => formData.append(e, validData[e]));
    formData.append("type", "menu");

    await updateData(formData, token);
    await dispatch(getMenus(userId));
    setShowEditMenu(false);
  }

  function handleChangeImage(e) {
    let imageFormData = new FormData();

    imageFormData.append("image", image);
    imageFormData.append("type", "menu");
    imageFormData.append("id", menuInfo.title);

    updateImage(imageFormData, token);
  }
  async function handleDelete() {
    await DeleteItem(menuInfo.id, "menu", token);
    await dispatch(getMenus(userId));
    setShowEditMenu(false);
  }

  function HandleRenderTab() {
    switch (selectTab) {
      case 0:
        return (
          <DataTab
            langs={langs}
            setLangs={setLangs}
            colors={colors}
            setColors={setColors}
            social={social}
            setSocial={setSocial}
            handleSubmit={handleSubmit}
            reSetData={reSetData}
            t={t}
          />
        );
      case 1:
        return (
          <ImageTab
            title={menuInfo.title}
            image={image}
            setImage={setImage}
            handleChangeImage={handleChangeImage}
            t={t}
          />
        );
      case 2:
        return (
          <UtilitiesTab
            setDelConfirm={setDelConfirm}
            isActive={isActive}
            handleOnSwtich={handleOnSwtich}
            t={t}
          />
        );
      case 3:
        return <EditSlideShow menuInfo={menuInfo} />;
      default:
        return <p>{t("error")}</p>;
    }
  }

  return !showEditMenu ? null : (
    <div className="d-flex fixed-top fixed-bottom bg-opacity-50 bg-dark">
      <div
        className="m-auto my-5 rounded overflow-hidden d-flex flex-column position-relative"
        style={{ maxWidth: "1000px", width: "90%" }}
      >
        <div className="d-flex p-2 justify-content-between bg-dark-subtle">
          <h5 className="mb-0"> {t("editMenuData")}</h5>
          <button
            type="button"
            className="btn-close bg-body"
            aria-label="Close"
            onClick={() => {
              setShowEditMenu(false);
              reSetData();
            }}
          ></button>
        </div>

        <div className="bg-white overflow-y-auto overflow-x-hidden rounded-bottom">
          <div className="d-flex overflow-x-auto bg-light">
            {["data", "image", "manage", "slides"].map((item, index) => (
              <button
                onClick={() => setSelectTab(index)}
                className={
                  "btn  rounded-0 " +
                  (selectTab === index
                    ? "btn-primary border-bottom"
                    : "btn-light")
                }
              >
                {t(item)}
              </button>
            ))}
          </div>
          <div className="p-4">{HandleRenderTab()}</div>
        </div>
        <DeletionConfirmationScreen
          title={menuInfo.title}
          delConfirm={delConfirm}
          setDelConfirm={setDelConfirm}
          handleDelete={handleDelete}
          t={t}
        />
      </div>
    </div>
  );
}

function DeletionConfirmationScreen({
  title,
  delConfirm,
  setDelConfirm,
  handleDelete,
  t,
}) {
  const [titleInp, setTitleInpt] = useState();

  const validDel = title === titleInp;

  return !delConfirm ? null : (
    <div
      className="bg-dark
 bg-opacity-10 position-fixed w-100 h-100 top-0 start-0 d-flex px-2"
      style={{ backdropFilter: "blur(5px)" }}
    >
      <div className="bg-white rounded p-3 m-auto">
        <h4>{t("deleteSure")}</h4>
        <div className="my-2">
          <label className="form-label">{t("deleteInsertTitle")}</label>
          <input
            className="form-control"
            type="text"
            value={titleInp}
            onChange={(e) => setTitleInpt(e.target.value)}
          />
        </div>
        <div>
          <button
            className="btn btn-danger"
            disabled={!validDel}
            onClick={() => handleDelete()}
          >
            {t("delete")}
          </button>
          <button
            className="ms-2 btn btn-secondary"
            onClick={() => setDelConfirm(false)}
          >
            {t("cancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

function DataTab({
  langs,
  setLangs,
  colors,
  setColors,
  social,
  setSocial,
  handleSubmit,
  reSetData,
  t,
}) {
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <LangsSwitch langs={langs} setLangs={setLangs} t={t} />
      <ColorsContainer colors={colors} setColors={setColors} t={t} />
      <SocialContainer social={social} setSocial={setSocial} t={t} />
      <div>
        <button className="btn btn-primary">{t("submit")}</button>
        <button
          type="button"
          className="btn btn-danger ms-2"
          onClick={() => reSetData()}
        >
          {t("cancelUndo")}
        </button>
      </div>
    </form>
  );
}

function ImageTab({ title, image, setImage, handleChangeImage, t }) {
  return (
    <div>
      <DoubleImageContainer
        oldimage={`https://menuapi.mubatech.com/images/logos/${title}.png`}
        image={image}
        setImage={setImage}
        t={t}
      />
      {!image ? null : (
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleChangeImage()}
          >
            {t("confirmImageChange")}
          </button>
          <button className="btn btn-danger" onClick={() => setImage()}>
            {t("cancel")}
          </button>
        </div>
      )}
    </div>
  );
}

function UtilitiesTab({ setDelConfirm, isActive, handleOnSwtich, t }) {
  return (
    <div>
      <h4>{t("menuManagment")}</h4>
      <MenuSwitch isActive={isActive} handleOnSwtich={handleOnSwtich} t={t} />
      <DeleteMenu setDelConfirm={setDelConfirm} t={t} />
    </div>
  );
}

function MenuSwitch({ isActive, handleOnSwtich }) {
  return (
    <div className="form-check form-switch my-4">
      <input
        className="form-check-input"
        type="checkbox"
        role="switch"
        id="menuSwtich"
        checked={isActive}
        onChange={(e) => {
          handleOnSwtich(e);
        }}
      />
      <label className="form-check-label" htmlFor="menuSwtich">
        {t("menuActivity")}
      </label>
    </div>
  );
}

function DeleteMenu({ setDelConfirm, t }) {
  return (
    <div>
      <button className="btn btn-danger" onClick={() => setDelConfirm(true)}>
        {t("deleteMenu")}
      </button>
    </div>
  );
}
