import QRCodeStyling from "qr-code-styling";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function CreateQr({ title, showQrMenu, setShowQrMenu }) {
  const qrContainer = useRef(null);

  const [dot, setDot] = useState({
    color: "#000000",
    type: "square",
  });
  const [cornerSquare, setCornerSquare] = useState({
    color: "#000000",
    type: "square",
  });
  const [cornerDot, setCornerDot] = useState({
    color: "#000000",
    type: "square",
  });
  const [qrSize, setQrSize] = useState(300);

  const [image, setImage] = useState();
  const [downloadType, setDownloadType] = useState("png");
  const [imageData, setImageData] = useState({ margin: 0, size: 0 });
  const [generalData, setGeneralData] = useState({
    qrShape: "square",
    qrMargin: 0,
    backgroundColor: "#ededed",
    backgroundRadius: 0,
  });

  const { t } = useTranslation();

  const qrCode = new QRCodeStyling({
    width: qrSize,
    height: qrSize,
    margin: generalData.qrMargin || 0,
    type: "canvas",
    data: `https://menu.mubatech.com/${title}`,
    image: image ? URL.createObjectURL(image) : null,
    shape: generalData.qrShape,
    dotsOptions: {
      color: dot.color,
      type: dot.type,
    },
    cornersSquareOptions: {
      type: cornerSquare.type,
      color: cornerSquare.color,
    },
    cornersDotOptions: {
      type: cornerDot.type,
      color: cornerDot.color,
    },

    backgroundOptions: {
      color: generalData.backgroundColor,
      round: generalData.backgroundRadius / 100 || 0,
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: imageData.margin || 0,
      imageSize: imageData.size / 10 || 0.4,
    },
  });

  useEffect(() => {
    qrContainer.current.innerHTML = "";

    qrCode.append(qrContainer.current);
  }, [qrCode]);

  function DownloadQR() {
    qrCode.download({ name: `${title}-qr`, extension: downloadType });
    console.log(downloadType);
  }

  return (
    <div className="d-flex fixed-top fixed-bottom bg-opacity-25 bg-dark p-2 overflow-hidden">
      <div className="m-auto rounded overflow-hidden h-100 d-flex flex-column">
        <div className="p-2 bg-dark-subtle d-flex justify-content-end">
          <button
            type="button"
            className="btn-close bg-light"
            onClick={() => setShowQrMenu(false)}
          ></button>
        </div>
        <div className="d-flex flex-md-row flex-column justify-content-md-center p-2 overflow-y-auto bg-light rounded-bottom">
          <div className="m-2">
            <div className="d-flex align-items-center justify-content-center mb-2 p-2 border">
              <div
                className="custom-canvas-dispaly-size"
                ref={qrContainer}
              ></div>
            </div>
            <div className="input-group">
              <button
                className="btn btn-sm btn-secondary mx-auto"
                onClick={DownloadQR}
              >
                {t("downlaodQR")}
              </button>
              <SetSize qrSize={qrSize} setQrSize={setQrSize} t={t} />
              <select
                value={downloadType}
                onChange={(e) => {
                  setDownloadType(e.target.value);
                }}
                className="form-select"
              >
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="svg">SVG</option>
                <option value="webp">WEBP</option>
              </select>
            </div>
          </div>
          <div className="m-2">
            <EditTypeAndColor dot={dot} setDot={setDot} t={t} />
            <EditCornerSquare
              cornerSquare={cornerSquare}
              setCornerSquare={setCornerSquare}
              t={t}
            />
            <EditCornerDot
              cornerDot={cornerDot}
              setCornerDot={setCornerDot}
              t={t}
            />
            <ImageManagment
              image={image}
              setImage={setImage}
              imageData={imageData}
              setImageData={setImageData}
              t={t}
            />

            <QRGeneralData
              generalData={generalData}
              setGeneralData={setGeneralData}
              t={t}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function SetSize({ qrSize, setQrSize, t }) {
  const sizes = [
    { size: 300, name: "small" },
    { size: 600, name: "medium" },
    { size: 1200, name: "large" },
  ];

  return (
    <select
      className="form-select"
      value={qrSize}
      onChange={(e) => setQrSize(e.target.value)}
    >
      {sizes.map((e) => (
        <option value={e.size}>{t(e.name)}</option>
      ))}
    </select>
  );
}

function EditTypeAndColor({ dot, setDot, t }) {
  const allTypes = [
    {
      value: "square",
      name: "square",
    },

    {
      value: "rounded",
      name: "rounded",
    },
    {
      value: "extra-rounded",
      name: "extraRounded",
    },
    {
      value: "dots",
      name: "dot",
    },

    {
      value: "classy",
      name: "classy",
    },
    {
      value: "classy-rounded",
      name: "classyRounded",
    },
  ];

  return (
    <div className="mb-2">
      <h5>{t("dotOption")}</h5>
      <EditData qrData={dot} setQrData={setDot} typesArray={allTypes} t={t} />
    </div>
  );
}

function EditCornerSquare({ cornerSquare, setCornerSquare, t }) {
  const allTypes = [
    {
      value: "square",
      name: "square",
    },
    {
      value: "extra-rounded",
      name: "extraRounded",
    },
    {
      value: "dot",
      name: "dot",
    },
  ];
  return (
    <div className="mb-2">
      <h5>{t("cornerSquareOptions")}</h5>
      <EditData
        qrData={cornerSquare}
        setQrData={setCornerSquare}
        typesArray={allTypes}
        t={t}
      />
    </div>
  );
}

function EditCornerDot({ cornerDot, setCornerDot, t }) {
  const allTypes = [
    {
      value: "square",
      name: "square",
    },

    {
      value: "dot",
      name: "dot",
    },
  ];

  return (
    <div className="mb-2">
      <h5>{t("cornerDotOptions")}</h5>

      <EditData
        qrData={cornerDot}
        setQrData={setCornerDot}
        typesArray={allTypes}
        t={t}
      />
    </div>
  );
}

function EditData({ qrData, setQrData, typesArray, t }) {
  return (
    <div className="d-flex gap-1 bg-dark-subtle p-1">
      <div>
        <input
          id="colorAll"
          type="color"
          className="form-control form-control-color"
          value={qrData.color}
          onChange={(e) => setQrData({ ...qrData, color: e.target.value })}
        />
      </div>
      <div className="w-100">
        <select
          className="form-select"
          value={qrData.type}
          onChange={(e) => setQrData({ ...qrData, type: e.target.value })}
        >
          {typesArray.map((entery) => (
            <option value={entery.value}>{t(entery.name)}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

function ImageManagment({ image, setImage, imageData, setImageData, t }) {
  const inputref = useRef(null);
  return (
    <div className="mb-2 mt-4">
      <h5>{t("centerImage")}</h5>
      <div className="mb-2 input-group">
        <input
          ref={inputref}
          type="file"
          accept="image/*"
          onChange={(e) => {
            if (e.target.files[0]) setImage(e.target.files[0]);
          }}
          className="form-control"
        />
        {image ? (
          <button
            onClick={(e) => {
              setImage();
              inputref.current.value = "";
            }}
            className="btn btn-outline-danger rounded-start-0"
          >
            {t("remove")}
          </button>
        ) : null}
      </div>

      <div className="d-flex gap-1">
        <div className="w-100">
          <label>{t("margin")}</label>
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min="0"
              value={imageData.margin}
              placeholder="0"
              onChange={(e) => {
                setImageData({
                  ...imageData,
                  margin: parseInt(e.target.value),
                });
              }}
            />
            <span className="input-group-text">px</span>
          </div>
        </div>
        <div className="w-100">
          <label>{t("size")}</label>
          <div className="input-group">
            <input
              className="form-control"
              type="number"
              min="0"
              max="10"
              placeholder="4"
              value={imageData.size}
              onChange={(e) => {
                setImageData({
                  ...imageData,
                  size: parseInt(e.target.value),
                });
              }}
            />
            <span className="input-group-text">px</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function QRGeneralData({ generalData, setGeneralData, t }) {
  return (
    <div>
      <h5>{t("qrOpt")}</h5>

      <div>
        <span>{t("background")}</span>
        <div className="d-flex gap-1">
          <input
            type="color"
            className="form-control form-control-color"
            value={generalData.backgroundColor}
            onChange={(e) => {
              setGeneralData({
                ...generalData,
                backgroundColor: e.target.value,
              });
            }}
          />

          <div className="input-group">
            <span className="input-group-text">{t("radius")}</span>
            <input
              type="number"
              className="form-control"
              value={generalData.backgroundRadius}
              min="0"
              max="100"
              placeholder="0"
              onChange={(e) => {
                if (
                  (0 <= parseInt(e.target.value) &&
                    parseInt(e.target.value) <= 100) ||
                  e.target.value === ""
                )
                  setGeneralData({
                    ...generalData,
                    backgroundRadius: parseInt(e.target.value),
                  });
              }}
            />
          </div>
        </div>
      </div>

      <div className="d-flex gap-1">
        <div className="w-100">
          <label>{t("shape")} </label>
          <select
            className="form-select"
            value={generalData.qrShape}
            onChange={(e) =>
              setGeneralData({ ...generalData, qrShape: e.target.value })
            }
          >
            <option value="square">{t("square")}</option>
            <option value="circle">{t("circle")}</option>
          </select>
        </div>

        <div className="w-100">
          <label htmlFor="qrMargin">{t("margin")}</label>

          <div className="input-group">
            <input
              type="number"
              id="qrMargin"
              className="form-control"
              value={generalData.qrMargin}
              min="0"
              placeholder="0"
              onChange={(e) =>
                setGeneralData({
                  ...generalData,
                  qrMargin: parseInt(e.target.value),
                })
              }
            />
            <span className="input-group-text">px</span>
          </div>
        </div>
      </div>
    </div>
  );
}
