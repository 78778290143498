import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
};

const updateLoaderSlice = createSlice({
  name: "updateLoader",
  initialState,
  reducers: {
    showLoader: (state) => {
      state.loader = true;
    },
    closeLoader: (state) => {
      state.loader = false;
    },
  },
});

export default updateLoaderSlice.reducer;

export const { showLoader, closeLoader } = updateLoaderSlice.actions;
