import { configureStore } from "@reduxjs/toolkit";
import { menuSlice } from "./menuSlice";
import menuFunctionsSlicer from "./menuFunctions/menuFunctionsSlicer";
import loaderSlice from "./updateLoader/updateLoaderSlice";
import { getMenusSlice } from "./getMenus/getMenus";
import screenScroolSlice from "./screenScroll/screenScroolSlice";
import preMenuLangSlice from "./preMenuLang/preMenuLangSlice";

export const store = configureStore({
  reducer: {
    menu: menuSlice.reducer,
    menuFunction: menuFunctionsSlicer,
    loader: loaderSlice,
    userMenus: getMenusSlice.reducer,
    screenSlice: screenScroolSlice,
    preMenuLang: preMenuLangSlice,
  },
});
