import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import editItem from "../images/editItem.svg";
import gear from "../images/gear.svg";
import CreateMenu from "../components/menuInfo/createMenu";
import EditMenuInfo from "../components/menuInfo/editMenuInfo";
import React, { useEffect, useRef, useState } from "react";
import { getMenus } from "../redux/getMenus/getMenus.js";
import { useDispatch, useSelector } from "react-redux";
import { isActiveApi } from "../services/menuServices";

import { useTranslation } from "react-i18next";
import CreateQr from "../components/menuInfo/createQr.js";
import {
  setScrollOff,
  setScrollOn,
} from "../redux/screenScroll/screenScroolSlice.js";
// import { getUserMenus } from "../services/menuServices";

const cookies = new Cookies();

export default function Home() {
  const { t } = useTranslation();
  const [makeMenu, setMakeMenu] = useState(false);
  const dispatch = useDispatch();

  const userId = cookies.get("userID");

  const { allMenu, allMenuSuccess } = useSelector((store) => store.userMenus);

  useEffect(() => {
    if (makeMenu === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [makeMenu]);

  useEffect(() => {
    dispatch(getMenus(userId));
  }, []);

  return (
    <div className="bg-body-secondary">
      <MenuHeader setMakeMenu={setMakeMenu} t={t} />

      <div className="container pt-4 ">
        {allMenuSuccess
          ? allMenu.map((menu) => {
              return (
                <MenuInfoContainer
                  key={menu.id}
                  menuID={menu.id}
                  menuInfo={menu}
                  title={menu.title}
                  menuActivity={menu.active}
                  t={t}
                />
              );
            })
          : [[], [], []].map((e, i) => {
              return (
                <div
                  key={"temp" + i}
                  className="border-opacity-10 mx-4 mb-4 rounded-4 d-flex justify-content-between custom-gradient-background-animated"
                >
                  <div className="w-100 d-flex justify-content-between p-3 gap-2 flex-wrap">
                    <div
                      className="custom-gradient-background-animated rounded-5"
                      style={{ height: "34px", width: "75px" }}
                    ></div>
                    <div
                      className="custom-gradient-background-animated rounded-5"
                      style={{
                        height: "34px",
                        maxWidth: "219px",
                        width: "50%",
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
      </div>

      <CreateMenu makeMenu={makeMenu} setMakeMenu={setMakeMenu} />
    </div>
  );
}

function MenuHeader({ setMakeMenu, t }) {
  const headerToolTipRef = useRef(null);
  const [headerToolTipView, setHeaderToolTipView] = useState(false);

  return (
    <React.Fragment>
      <header className=" py-2 bg-body border-bottom">
        <div className="container d-flex justify-content-between">
          <div className="text-center align-self-center">
            <h2 className=" fw-bold">{t("menuManage")}</h2>
          </div>
          <div className="d-flex gap-4 ">
            <button
              className="btn btn-primary border my-auto fw-bold d-sm-block d-none"
              style={{ width: "max-content" }}
              onClick={() => setMakeMenu(true)}
            >
              {t("createaMenu")}
            </button>
            <div className="position-relative my-auto" ref={headerToolTipRef}>
              <button
                className="btn btn-primary my-auto"
                onClick={() => setHeaderToolTipView(!headerToolTipView)}
              >
                {t("settings")}
              </button>
              <HeaderToolTip
                headerToolTipRef={headerToolTipRef}
                setHeaderToolTipView={setHeaderToolTipView}
                headerToolTipView={headerToolTipView}
                t={t}
              />
            </div>
          </div>
        </div>
      </header>
      <div className="bg-light border-bottom d-sm-none d-block">
        <div className="container py-2 d-flex justify-content-end">
          <button
            className="btn btn-primary border my-auto fw-bold "
            style={{ width: "max-content" }}
            onClick={() => setMakeMenu(true)}
          >
            {t("createaMenu")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

function MenuInfoContainer({ menuID, menuInfo, title, menuActivity, t }) {
  const [isActive, setIsActive] = useState(menuActivity);
  const [showEditMenu, setShowEditMenu] = useState(false);

  const [showQrMenu, setShowQrMenu] = useState(false);

  const [toolTipView, setToolTipView] = useState(false);
  const toolTipRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showEditMenu === true || showQrMenu === true) {
      dispatch(setScrollOff());
    } else {
      dispatch(setScrollOn());
    }
  }, [showEditMenu, showQrMenu]);

  function handleOnSwtich(e) {
    const token = cookies.get("token");

    setIsActive(e.target.checked);

    isActiveApi(menuID, e.target.checked, token);
  }

  return (
    <React.Fragment>
      <div
        className="bg-light border-opacity-10 mx-4 mb-3 rounded-2 d-flex justify-content-between"
        key={title}
      >
        <div className="w-100 d-flex  flex-sm-row flex-column justify-content-between p-3 gap-2 flex-wrap">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="mb-0 position-relative">
              {title}
              {isActive ? (
                <span className="position-absolute top-0 start-100 translate-middle bg-success border border-light rounded-circle p-1">
                  <span className="visually-hidden">Menu is On</span>
                </span>
              ) : (
                <span className="position-absolute top-0 start-100 translate-middle bg-danger border border-light rounded-circle p-1">
                  <span className="visually-hidden">Menu Is Off</span>
                </span>
              )}
            </h4>
            <label className="menu-check-box-switch d-sm-none d-block">
              <input
                type="checkbox"
                className="menu-check-box"
                checked={isActive}
                onChange={(e) => {
                  handleOnSwtich(e);
                }}
              />
              <span className="menu-check-box-slider"></span>
            </label>
          </div>
          <hr className="my-2 mx-2 d-sm-none d-block" />
          <div className="d-flex align-items-center justify-content-sm-end justify-content-between gap-2">
            <label className="menu-check-box-switch d-sm-block d-none">
              <input
                type="checkbox"
                className="menu-check-box"
                checked={isActive}
                onChange={(e) => {
                  handleOnSwtich(e);
                }}
              />
              <span className="menu-check-box-slider"></span>
            </label>

            <Link
              to={`Edit/${title}`}
              className="btn btn-primary btn-sm d-flex gap-1"
            >
              <img src={editItem} style={{ width: "20px" }} alt="editItem" />
              <span>{t("editMenu")}</span>
            </Link>
            <div ref={toolTipRef} className="position-relative">
              <button
                onClick={() => setToolTipView((previous) => !previous)}
                className="btn btn-sm"
              >
                <img src={gear} alt="o" style={{ width: "20px" }} />
              </button>
              <ToolTip
                title={menuInfo.title}
                toolTipRef={toolTipRef}
                toolTipView={toolTipView}
                setToolTipView={setToolTipView}
                setShowEditMenu={setShowEditMenu}
                t={t}
                setShowQrMenu={setShowQrMenu}
              />
            </div>
          </div>
        </div>
      </div>
      <EditMenuInfo
        menuInfo={menuInfo}
        showEditMenu={showEditMenu}
        setShowEditMenu={setShowEditMenu}
        isActive={isActive}
        handleOnSwtich={handleOnSwtich}
      />

      {showQrMenu ? (
        <CreateQr
          title={title}
          showQrMenu={showQrMenu}
          setShowQrMenu={setShowQrMenu}
        />
      ) : null}
    </React.Fragment>
  );
}

function ToolTip({
  title,
  toolTipRef,
  toolTipView,
  setToolTipView,
  setShowEditMenu,

  t,
  setShowQrMenu,
}) {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [toolTipRef]);

  function handleClickOutside(event) {
    if (toolTipRef.current && !toolTipRef.current.contains(event.target)) {
      setToolTipView(false);
    }
  }

  return toolTipView ? (
    <div className="position-absolute bg-white border border-dark-subtle rounded-2 z-3 end-0 overflow-hidden">
      <button
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        onClick={() => {
          setShowEditMenu(true);
          setToolTipView(false);
        }}
      >
        {t("editMenu")}
      </button>

      <button
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        onClick={() => {
          setToolTipView(false);
          setShowQrMenu(true);
        }}
      >
        {t("genQR")}
      </button>

      <Link
        className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
        target="_blank"
        to={"https://menu.mubatech.com/" + title}
      >
        {t("toMenu")}
      </Link>
    </div>
  ) : null;
}

function HeaderToolTip({
  headerToolTipRef,
  setHeaderToolTipView,
  headerToolTipView,

  t,
}) {
  const { i18n } = useTranslation();
  const [viewLangList, setViewLangList] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [headerToolTipRef]);

  function handleClickOutside(event) {
    if (
      headerToolTipRef.current &&
      !headerToolTipRef.current.contains(event.target)
    ) {
      setHeaderToolTipView(false);
      setViewLangList(false);
    }
  }

  function logOut() {
    cookies.set("isLogined", false);
    cookies.remove("token");
    cookies.remove("userID");

    sessionStorage.removeItem("isLogined");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userID");

    window.location.reload(true);
  }
  const languageList = {
    en: { nickName: "English" },
    ar: { nickName: "Arabic" },
  };

  return headerToolTipView ? (
    <div className="position-absolute z-3 end-50 mt-1 d-flex gap-1">
      {viewLangList ? (
        <div
          className="bg-light border border-dark-subtle rounded-2 py-2"
          style={{ height: "min-content" }}
        >
          {Object.keys(languageList).map((indLang) => (
            <button
              className={`btn btn-light btn-sm w-100 px-2 rounded-0 fw-semibold ${
                indLang === i18n.resolvedLanguage
                  ? "text-primary"
                  : "text-dark-emphasis"
              }`}
              onClick={() => {
                i18n.changeLanguage(indLang);
                setHeaderToolTipView(false);
                setViewLangList(false);
              }}
            >
              {t(languageList[indLang].nickName)}
            </button>
          ))}
        </div>
      ) : null}
      <div className="bg-light border border-dark-subtle rounded-2 py-2">
        <button
          className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
          onClick={() => setViewLangList(!viewLangList)}
        >
          {t("changeLang")}
        </button>

        <button
          className="btn btn-sm btn-light text-danger text-bold text-nowrap w-100 rounded-0"
          onClick={logOut}
          type="button"
          name=""
          id=""
        >
          {t("logOut")}
        </button>
      </div>
    </div>
  ) : null;
}
