import { useState } from "react";
import missingImage from "../../images/default-missin.png";
import Cropper from "react-easy-crop";
import Resizer from "react-image-file-resizer";
import getCroppedImg from "../../services/createImage";

export function DataContainer({ data, setData, t }) {
  return (
    <section>
      <h4>{t("siteData")}</h4>
      <div className="">
        <div className="">
          <label htmlFor="siteTitle" className="form-label">
            {t("siteTitle")}
          </label>
          <input
            type="text"
            className="form-control"
            id="siteTitle"
            required
            value={data.title}
            onChange={(e) => {
              console.log(e.target.value);
              if (
                e.target.value.match("^[\x20-\x7F]+$") ||
                e.target.value === ""
              )
                setData({
                  ...data,
                  title: e.target.value.replace(/\s/g, "").toLocaleLowerCase(),
                });
            }}
          />
          <div id="titleHelp" className="form-text">
            {t("siteTitleNote")}
          </div>
        </div>
      </div>
    </section>
  );
}

export function LangsSwitch({ langs, setLangs, t }) {
  const oneSelected = Object.values(langs).includes(true);

  const fullname = {
    en: "English",
    ar: "Arabic",
    ku: "Kurdish",
    tr: "Turkish",
  };
  return (
    <section className="my-3">
      <h4>{t("languages")}</h4>
      <div className="d-flex gap-1 flex-wrap">
        {Object.keys(langs).map((langName) => {
          return (
            <div key={langName} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={langName + "check"}
                required={!oneSelected}
                checked={langs[langName]}
                onChange={(e) => {
                  setLangs({ ...langs, [langName]: e.target.checked });
                }}
              />
              <label className="form-check-label" htmlFor={langName + "check"}>
                {t(fullname[langName])}
              </label>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export function ColorsContainer({ colors, setColors, t }) {
  return (
    <section className="my-3">
      <h4> {t("color")} </h4>
      <div className="d-flex gap-2 flex-wrap gap-2">
        {Object.keys(colors).map((currentColor) => {
          return (
            <div key={currentColor} className="d-flex align-items-center">
              <input
                type="color"
                className="form-control form-control-color rounded-end-0"
                // style={{ flex: "0 0 auto", width: "3rem" }}
                id="exampleColorInput"
                value={colors[currentColor]}
                title={"Choose your " + t(currentColor, { lng: "en" })}
                onChange={(e) =>
                  setColors({ ...colors, [currentColor]: e.target.value })
                }
              />
              <label
                className="input-group-text rounded-start-0"
                htmlFor="exampleColorInput"
              >
                {t(currentColor)}
              </label>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export function getNormalFont(hexcolor) {
  const numarr = hexcolor.toLowerCase().match(/[0-9a-f]{2}/g);
  const decimalNum = numarr.map((hexString) => parseInt(hexString, 16));
  const addGlare = decimalNum.map((indCol) =>
    Math.ceil((indCol + 12.75) / 1.05)
  );
  const decToHex = addGlare.map((hexNum) => hexNum.toString(16));
  const normalColor = decToHex.join("");
  return "#" + normalColor;
}

export function ImageContainer({ image, setImage, t }) {
  return (
    <section>
      <h4>{t("image")}</h4>
      <div>
        <img
          className="img-thumbnail w-100 h-100 object-fit-contain"
          src={image ? URL.createObjectURL(image) : missingImage}
          alt="menu-img"
        />
        <input
          type="file"
          required
          className="form-control"
          accept=".png"
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
        />
      </div>
    </section>
  );
}

export function DoubleImageContainer({ oldimage, image, setImage, t }) {
  return (
    <section className="mb-3">
      <h4 className="pb-2">{t("image")}</h4>
      <div className="row gap-md-0 gap-sm-1">
        <div className="col-md-6 col-sm-12">
          <div
            className="w-100"
            style={{ aspectRatio: "4/3", overflow: "hidden" }}
          >
            <img
              src={oldimage ? oldimage : missingImage}
              className="img-thumbnail bg-dark-subtle w-100 h-100 object-fit-contain"
              alt="oldImage"
            />
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div
            className="w-100 mb-2"
            style={{ aspectRatio: "4/3", overflow: "hidden" }}
          >
            <img
              src={image ? URL.createObjectURL(image) : missingImage}
              className="img-thumbnail bg-dark-subtle w-100 h-100 object-fit-contain"
              alt="newImage"
            />
          </div>

          <input
            type="file"
            className="form-control"
            accept=".png"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
        </div>
      </div>
    </section>
  );
}

export function SocialContainer({ social, setSocial, t }) {
  return (
    <section className="my-3">
      <h4>{t("social")}</h4>

      <div className="row">
        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="phoneNum" className="form-label">
            {t("phone")}
          </label>
          <input
            type="number"
            className="form-control"
            id="phoneNum"
            value={social.phone}
            onChange={(e) => setSocial({ ...social, phone: e.target.value })}
          />
        </div>

        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="address" className="form-label">
            {t("address")}
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            value={social.address}
            onChange={(e) => setSocial({ ...social, address: e.target.value })}
          />
        </div>

        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="Youtube" className="form-label">
            {t("youtube")}
          </label>
          <input
            type="text"
            className="form-control"
            id="Youtube"
            value={social.youtube}
            onChange={(e) => setSocial({ ...social, youtube: e.target.value })}
          />
        </div>

        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="insta" className="form-label">
            {t("instagram")}
          </label>
          <input
            type="text"
            className="form-control"
            id="insta"
            value={social.insta}
            onChange={(e) => setSocial({ ...social, insta: e.target.value })}
          />
        </div>

        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="snap" className="form-label">
            {t("snapchat")}
          </label>
          <input
            type="text"
            className="form-control"
            id="snap"
            value={social.snap}
            onChange={(e) => setSocial({ ...social, snap: e.target.value })}
          />
        </div>

        <div className="col-md-6 my-1 col-sm-12">
          <label htmlFor="fb" className="form-label">
            {t("facebook")}
          </label>
          <input
            type="text"
            className="form-control"
            id="fb"
            value={social.fb}
            onChange={(e) => setSocial({ ...social, fb: e.target.value })}
          />
        </div>
      </div>
    </section>
  );
}

export function SubmitData({ setShow, emptyData = () => {}, t }) {
  return (
    <section>
      <button className="btn btn-primary">{t("sumbit")}</button>
      <button
        onClick={() => {
          setShow(false);
          emptyData();
        }}
        type="button"
        className="btn btn-danger ms-2"
      >
        {t("cancel")}
      </button>
    </section>
  );
}
// export function SlideShowContainer({
//   hasSlideShow,
//   setHasSlideShow,
//   slideShowImages,
//   setSlideShowImages,
// }) {
//   return (
//     <div className="my-3">
//       <div class="form-check form-switch">
//         <input
//           class="form-check-input"
//           type="checkbox"
//           role="switch"
//           id="slideShowSwitch"
//           checked={hasSlideShow}
//           onChange={() => setHasSlideShow((previous) => !previous)}
//         />
//         <label class="form-check-label" for="slideShowSwitch">
//           Slideshow
//         </label>
//       </div>
//       {hasSlideShow ? (
//         <div>
//           <div className="d-flex overflow-x-scroll bg-body-secondary">
//             {slideShowImages.map((imageSrc, imgIndex) => (
//               <div
//                 className="m-2 position-relative"
//                 style={{
//                   aspectRatio: "16/8",
//                   height: "150px",
//                 }}
//               >
//                 <img
//                   src={URL.createObjectURL(imageSrc)}
//                   className="overflow-hidden w-100 h-100 "
//                   style={{ objectFit: "cover" }}
//                 />
//                 <button
//                   className="position-absolute top-0 end-0 bg-white border border-dark btn-close"
//                   type="button"
//                   onClick={() =>
//                     setSlideShowImages(
//                       slideShowImages.filter((e, i) => i !== imgIndex)
//                     )
//                   }
//                 ></button>
//               </div>
//             ))}
//           </div>
//           <div className="mt-2">
//             <input
//               type="file"
//               accept=".jpg"
//               className="form-control"
//               style={{ maxWidth: "240px" }}
//               onChange={(e) => {
//                 setSlideShowImages([...slideShowImages, e.target.files[0]]);
//               }}
//             />
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
// }
export function SlideShowContainerEdit({
  hasSlideShow,
  setHasSlideShow,
  serverImages,
  slideShowImages,
  setSlideShowImages,
  t,
}) {
  const initialCropData = { imageURL: null, view: false };
  const [imageUrl, setImageUrl] = useState();
  const [imageToCrop, setImageToCrop] = useState(initialCropData);

  function onFileChange(event) {
    const reader = new FileReader();
    reader.readAsDataURL(event);
    reader.addEventListener("load", () => {
      setImageUrl(reader.result);
    });

    setImageToCrop({ ...imageToCrop, view: true });
  }

  return (
    <div>
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          role="switch"
          id="slideShowSwitch"
          checked={hasSlideShow}
          onChange={() => setHasSlideShow((previous) => !previous)}
        />
        <label class="form-check-label" for="slideShowSwitch">
          {hasSlideShow ? t("slideshowEnabled") : t("slideshowDisabled")}
        </label>
      </div>

      <div className="my-md-2 my-5">
        <div className="row mx-0 bg-body-secondary">
          {serverImages.map((imageSrc) => (
            <div className="p-1 m-0 col-lg-4 col-md-6 col-12 ">
              <div
                className="custom-gradient-background-animated "
                style={{
                  aspectRatio: "2/1",
                }}
              >
                <img
                  src={imageSrc}
                  className="overflow-hidden h-100 w-100 "
                  style={{ objectFit: "cover" }}
                  alt="imgSrc"
                />
              </div>
              <div>
                <button
                  className="btn btn-danger w-100 rounded-top-0 rouned-bottom"
                  disabled
                >
                  {t("remove")}
                </button>
              </div>
            </div>
          ))}

          {slideShowImages
            ? slideShowImages.map((imageSrc, imgIndex) => (
                <div className="p-1 m-0 col-lg-4 col-md-6 col-12">
                  <div
                    className="custom-gradient-background-animated overflow-hidden position-relative"
                    style={{
                      aspectRatio: "2/1",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(imageSrc)}
                      className="overflow-hidden w-100 h-100 "
                      style={{ objectFit: "cover" }}
                      alt="imgSrc"
                    />
                  </div>
                  <div className="d-flex align-items-center w-100 overflow-hidden rounded-bottom ">
                    <button
                      className="btn btn-primary rounded-0 w-100"
                      onClick={() => {
                        setImageToCrop({ ...imageToCrop, view: true });
                      }}
                    >
                      {t("edit")}
                    </button>

                    <button
                      className="btn btn-danger rounded-0 w-100"
                      type="button"
                      onClick={() =>
                        setSlideShowImages(
                          slideShowImages.filter((e, i) => i !== imgIndex)
                        )
                      }
                    >
                      {t("remove")}
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
        <div className="mt-md-2 mt-5">
          <label className="form-label">{t("addImageSlide")}</label>
          <input
            type="file"
            accept=".jpg"
            className="form-control"
            style={{ maxWidth: "240px" }}
            onChange={(e) => {
              if (e.target.files[0]) {
                // setSlideShowImages([e.target.files[0]]);
                onFileChange(e.target.files[0]);
                setImageToCrop({
                  ...imageToCrop,
                  view: true,
                });
              }
            }}
          />
        </div>
      </div>
      <WideImageCropDialogue
        imageView={imageToCrop.view}
        imageURL={imageUrl}
        cropInit={imageToCrop.crop}
        zoomInit={imageToCrop.zoom}
        aspectInit={2 / 1}
        setImageToCrop={setImageToCrop}
        setNewImage={setSlideShowImages}
        t={t}
      />
    </div>
  );
}

function WideImageCropDialogue({
  imageView,
  imageURL,
  cropInit = { x: 0, y: 0 },
  zoomInit = 1,
  aspectInit,
  setImageToCrop,
  setNewImage,
  t,
}) {
  const [crop, setCrop] = useState(cropInit);
  const [zoom, setZoom] = useState(zoomInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  function onZoomChange(zoom) {
    setZoom(zoom);
  }
  function onCropChange(crop) {
    setCrop(crop);
  }
  function onCropComplete(croppedArea, croppedAreaPixels) {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  function resizeFile(file) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        512,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob",
        1024,
        512
      );
    });
  }

  const onResizeCall = async (event) => {
    try {
      const image = await resizeFile(event);
      return image;
    } catch (err) {
      console.log(err);
    }
  };

  async function onCrop() {
    const croppedImageUrl = await getCroppedImg(imageURL, croppedAreaPixels);

    const resizedImage = await onResizeCall(croppedImageUrl);

    const imageFile = new File(
      [resizedImage],
      "name.jpg",
      { type: "image/jpeg" },
      { lastModifiedDate: new Date() }
    );

    setImageToCrop({ crop, zoom, view: false });
    setNewImage([imageFile]);
  }

  function onCancel() {
    setZoom(zoomInit);
    setCrop(cropInit);
    setImageToCrop((previous) => ({ ...previous, view: false }));
  }

  return imageView ? (
    <div>
      <div
        style={{ zIndex: "100" }}
        className="overflow-y-scroll position-fixed top-0 bottom-0 start-0 end-0 bg-black bg-opacity-25"
      >
        <div
          className="bg-dark-subtle rounded mx-sm-auto mx-2 my-2"
          style={{
            maxWidth: "600px",
            minWidth: "300px",
          }}
        >
          <div className="p-2">
            <h4>{t("imageCropping")}</h4>
          </div>
          <div
            className="position-relative m-auto w-100 custom-stripes-background"
            style={{
              aspectRatio: "3/2",
            }}
          >
            <Cropper
              image={imageURL}
              zoom={zoom}
              crop={crop}
              aspect={aspectInit}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="p-2 ">
            <input
              type="range"
              className="form-range my-4"
              step={0.01}
              min={1}
              max={3}
              value={zoom}
              onInput={(e) => {
                onZoomChange(e.target.value);
              }}
            />
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={onCrop}
              >
                {t("crop")}
              </button>
              <button
                type="button"
                className="btn btn-light w-100"
                onClick={onCancel}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
