import { Link } from "react-router-dom";

export default function MissingPage() {
  return (
    <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="text-center">
        <h2 className="mb-0" style={{ fontSize: "5rem" }}>
          404
        </h2>
        <h2 className="h5 mb-3">Page not found</h2>
        <p className="mb-4 text-body-secondary">
          {" "}
          Sorry, We couldn't find the Page you were looking for{" "}
        </p>
        <Link to="/" className="btn btn-primary rounded-1 px-5">
          Go Back Home
        </Link>
      </div>
    </div>
  );
}
