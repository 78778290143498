import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuLang: "",
};

const menuFunctionsSlicer = createSlice({
  name: "preMenuLang",
  initialState,
  reducers: {
    setToArabic: (state) => {
      state.menuLang = "ar";
    },
    setToEnglish: (state) => {
      state.menuLang = "en";
    },
    setToTurkish: (state) => {
      state.menuLang = "tr";
    },
    setToKurdish: (state) => {
      state.menuLang = "kr";
    },
    setToRandLang: (state, action) => {
      state.menuLang = action.payload;
    },
  },
});

export default menuFunctionsSlicer.reducer;

export const {
  setToArabic,
  setToEnglish,
  setToKurdish,
  setToTurkish,
  setToRandLang,
} = menuFunctionsSlicer.actions;
