import React, { useRef, useState } from "react";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import "../edit/menuItem.css";
import dragLines from "../../images/dragLines.svg";
import upArrrow from "../../images/upArrow.svg";
import gear from "../../images/gear.svg";
import plusSign from "../../images/plus.svg";

import {
  changeRank,
  isActiveCatagoryApi,
  isActiveItem,
  isActiveSub,
} from "../../services/menuServices";

import { viewEdit } from "../../redux/menuFunctions/menuFunctionsSlicer";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { reArangeCat, reArangeItem, reArangeSub } from "../../redux/menuSlice";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

const token = cookies.get("token");

export default function MenuItems({ siteData, lang }) {
  const dispatch = useDispatch();
  const [moving, setMoving] = useState(false);
  const { t } = useTranslation();

  function handleDragEnd(event) {
    const activeIndx = siteData.data.findIndex((e) => e.id === event.active.id);
    const overIndex = siteData.data.findIndex((e) => e.id === event.over.id);

    const formData = new FormData();
    const newArray = arrayMove(siteData.data, activeIndx, overIndex);
    const rankAndId = newArray.map((e, i) => ({ id: e.id, rank: i }));

    if (event.active.id !== event.over.id) {
      dispatch(reArangeCat({ activeIndx, overIndex }));
      formData.append("type", "category");
      formData.append("id", siteData.menu_id);
      formData.append("data", JSON.stringify(rankAndId));
      changeRank(formData, token);
    }

    setMoving(false);
  }

  function isStart() {
    setMoving(true);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 12,
      },
    })
  );

  return (
    <div className="p-3">
      <button
        id="addCatagory"
        className="add-item-container"
        onClick={() => {
          dispatch(
            viewEdit({
              selectType: "newCatagory",
            })
          );
        }}
      >
        <span className="add-item-container-name">
          <img src={plusSign} alt="+" />
          {t("addCat")}
        </span>
      </button>

      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={isStart}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext
          items={siteData.data}
          strategy={verticalListSortingStrategy}
        >
          {siteData.data.map((e, index) => {
            return (
              <CatagoryContainer
                key={e.id}
                name={e.name[lang]}
                id={e.id}
                catIndex={index}
                items={e.items}
                active={e.active}
                allData={e}
                moving={moving}
                sensors={sensors}
                siteData={siteData}
                t={t}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
}

function CatagoryContainer({
  name,
  id,
  catIndex,
  items,
  active,
  allData,
  moving,
  sensors,
  siteData,
  t,
}) {
  const functionSectionRef = useRef();

  const dispatch = useDispatch();
  let [showItem, setShowItems] = useState(false);

  function handleIsActive(thisid, condition) {
    const token = cookies.get("token");

    isActiveCatagoryApi(thisid, condition ? true : false, token);
  }

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleDragEndItem(event) {
    const activeIndex = items.findIndex((e) => e.id === event.active.id);
    const overIndex = items.findIndex((e) => e.id === event.over.id);

    const formData = new FormData();
    const newArray = arrayMove(items, activeIndex, overIndex);
    const rankAndId = newArray.map((e, i) => ({ id: e.id, rank: i }));

    if (event.active.id !== event.over.id) {
      dispatch(reArangeItem({ catIndex, activeIndex, overIndex }));
      formData.append("type", "item");
      formData.append("id", id);
      formData.append("data", JSON.stringify(rankAndId));
      changeRank(formData, token);
    }
  }

  function handleClickCatagory(e) {
    if (
      e.target === functionSectionRef.current ||
      functionSectionRef.current.contains(e.target)
    ) {
      console.log("is functionSection");
      console.log("inside of Function section");
    } else {
      dispatch(
        viewEdit({
          selectType: "editCatagory",
          id: id,
          allData: allData,
        })
      );
    }
  }
  return (
    <div ref={setNodeRef} style={{ ...style, cursor: "auto" }}>
      <div className="catagory-container__edit">
        <div
          className="cat-drag-icon-container"
          {...attributes}
          {...listeners}
          style={{ cursor: "grab" }}
        >
          <img className="cat-drag-icon " src={dragLines} alt=":::" />
        </div>

        <div
          className="catagory-container__edit__item-info-wrapper"
          style={{ cursor: "pointer" }}
          onClick={handleClickCatagory}
        >
          <div className="catagory-container__edit-image">
            <img
              className="catagory-container__edit-image-img"
              src={`https://menuapi.mubatech.com/images/categories/${id}.png`}
              alt={name}
            />
            <span className="catagory-container__name">{name}</span>
          </div>

          <div
            ref={functionSectionRef}
            className="catagory-container__edit-function"
          >
            <label className="menu-check-box-switch">
              <input
                className="menu-check-box"
                type="checkbox"
                defaultChecked={
                  active === "1" || active === 1 || active === true
                    ? true
                    : false
                }
                onChange={(e) => {
                  handleIsActive(id, e.target.checked);
                }}
              />
              <span className="menu-check-box-slider"></span>
            </label>

            <button
              className="catagory-container__edit-view-item-butttons"
              onClick={() => setShowItems(!showItem)}
            >
              <img
                className="view-item-butttons-img"
                src={upArrrow}
                style={{
                  transform: showItem ? "rotate(0deg)" : "rotate(-180deg)",
                }}
                alt="↓"
              />
            </button>
          </div>
        </div>
      </div>

      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEndItem}
        sensors={sensors}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {showItem && !moving ? (
            <div className="catagory-item-left-margin">
              <React.Fragment>
                {items.map((e, index) => {
                  return e.have_sub === "1" ||
                    e.have_sub === 1 ||
                    e.have_sub === true ? (
                    <SubContainer
                      key={e.name + e.id}
                      id={e.id}
                      enable={e.enable}
                      name={e.name}
                      sub={e.sub}
                      allData={e}
                      sensors={sensors}
                      catIndex={catIndex}
                      itemIndex={index}
                      siteData={siteData}
                      t={t}
                    />
                  ) : (
                    <Items
                      key={e.name + e.id}
                      id={e.id}
                      enable={e.enable}
                      name={e.name}
                      isMultiPrice={e.have_price}
                      price={e.price}
                      prices={e.item_price}
                      img={"/items/thumbnail/"}
                      type="item"
                      allData={e}
                      t={t}
                    />
                  );
                })}
                <button
                  id="addItem"
                  className="add-item-container"
                  onClick={() => {
                    dispatch(
                      viewEdit({
                        selectType: "newItem",
                        id: id,
                      })
                    );
                  }}
                >
                  <span className="add-item-container-name">
                    <img src={plusSign} alt="+" />
                    {t("addItem")}
                  </span>
                </button>
              </React.Fragment>
            </div>
          ) : null}
        </SortableContext>
      </DndContext>
    </div>
  );
}

function Items({
  id,
  enable,
  name,
  isMultiPrice,
  prices,
  price,
  img,
  type,
  allData,
  t,
}) {
  const dispatch = useDispatch();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleEnable(e) {
    let token = cookies.get("token");

    type === "item" ? isActiveItem(id, e, token) : isActiveSub(id, e, token);
  }

  function getMaxAndMinPrice(priceArray) {
    if (priceArray.length > 0) {
      let smallest = parseFloat(priceArray[0].price);
      priceArray.forEach((e) => {
        if (parseFloat(smallest) > parseFloat(e.price)) {
          smallest = parseFloat(e.price);
        }
      });
      let biggest = parseFloat(priceArray[0].price);
      priceArray.forEach((e) => {
        if (parseFloat(biggest) < parseFloat(e.price)) {
          biggest = parseFloat(e.price);
        }
      });

      return `${smallest.toLocaleString("en-US")} 
    - 
    ${biggest.toLocaleString("en-US")} `;
    } else {
      return 0;
    }
  }

  const itemRight = useRef();

  function handleClickItem(event) {
    if (
      event.target === itemRight.current ||
      itemRight.current.contains(event.target)
    ) {
    } else {
      dispatch(
        viewEdit({
          selectType: type === "item" ? "editItem" : "editSub",
          id: id,
          allData: allData,
        })
      );
    }
  }

  return (
    <div className="catagory-container__edit" ref={setNodeRef} style={style}>
      <div className="cat-drag-icon-container" {...attributes} {...listeners}>
        <img className="cat-drag-icon" src={dragLines} alt=":::" />
      </div>
      <div
        className="catagory-container__edit__item-info-wrapper"
        onClick={handleClickItem}
      >
        <div className="catagory-container__edit-image">
          <img
            className="catagory-container__edit-image-img"
            src={`https://menuapi.mubatech.com/images${img}${id}.jpg`}
            alt={name.en ? name.en : name.ar ? name.ar : name.ku}
          />
        </div>

        <div className="catagory-container__edit-info-container">
          <span className="catagory-container__name">
            {name.en ? name.en : name.ar ? name.ar : name.ku}
          </span>
          <div className="item-price-container">
            <span className="item-price-container-value">
              {isMultiPrice ? getMaxAndMinPrice(prices) : price}
            </span>
            <span className="item-price-container-currency">IQD</span>
          </div>
        </div>

        <div className="catagory-container__edit-function" ref={itemRight}>
          <label className="menu-check-box-switch">
            <input
              type="checkbox"
              className="menu-check-box"
              defaultChecked={
                enable === "1" || enable === 1 || enable === true ? true : false
              }
              onChange={(e) => {
                handleEnable(e.target.checked);
              }}
            />
            <span className="menu-check-box-slider"></span>
          </label>
        </div>
      </div>
    </div>
  );
}

function SubContainer({
  id,
  enable,
  name,
  sub,
  allData,
  sensors,
  catIndex,
  itemIndex,
  t,
}) {
  let [subItemView, setSubItemView] = useState(false);
  const dispatch = useDispatch();

  function enableItem(e) {
    let token = cookies.get("token");
    isActiveItem(id, e, token);
  }

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  function handleDragEndSub(event) {
    const activeIndex = sub.findIndex((e) => e.id === event.active.id);
    const overIndex = sub.findIndex((e) => e.id === event.over.id);

    const formData = new FormData();
    const newArray = arrayMove(sub, activeIndex, overIndex);
    const rankAndId = newArray.map((e, i) => ({ id: e.id, rank: i }));

    if (event.active.id !== event.over.id) {
      dispatch(reArangeSub({ catIndex, itemIndex, activeIndex, overIndex }));

      formData.append("type", "sub");
      formData.append("id", id);
      formData.append("data", JSON.stringify(rankAndId));

      changeRank(formData, token);
    }
  }

  const rightSide = useRef();

  function handleClickSub(event) {
    if (
      rightSide.current.contains(event.target) ||
      event.target === rightSide.current
    ) {
    } else {
      dispatch(
        viewEdit({
          selectType: "editItem",
          id: id,
          allData: allData,
        })
      );
    }
  }

  return (
    <div>
      <div className="catagory-container__edit" ref={setNodeRef} style={style}>
        <div className="cat-drag-icon-container" {...attributes} {...listeners}>
          <img className="cat-drag-icon" src={dragLines} alt=":::" />
        </div>

        <div
          className="catagory-container__edit__item-info-wrapper"
          onClick={handleClickSub}
        >
          <div className="catagory-container__edit-image">
            <div>{name.en}</div>
          </div>

          <div className="catagory-container__edit-function" ref={rightSide}>
            <label className="menu-check-box-switch">
              <input
                type="checkbox"
                className="menu-check-box"
                defaultChecked={
                  enable === "1" || enable === 1 || enable === true
                    ? true
                    : false
                }
                onChange={(e) => {
                  enableItem(e.target.checked);
                }}
              />
              <span className="menu-check-box-slider"></span>
            </label>

            <button
              className="catagory-container__edit-view-item-butttons"
              onClick={() => setSubItemView(!subItemView)}
            >
              <img
                className="view-item-butttons-img"
                src={upArrrow}
                style={{
                  transform: subItemView ? "rotate(0deg)" : "rotate(-180deg)",
                }}
                alt="↓"
              />
            </button>
          </div>
        </div>
      </div>

      <section className="catagory-item-left-margin">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEndSub}
          sensors={sensors}
          modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        >
          <SortableContext items={sub} strategy={verticalListSortingStrategy}>
            {subItemView
              ? sub.map((e) => {
                  return (
                    <Items
                      key={e.name + e.id}
                      id={e.id}
                      enable={e.enable}
                      name={e.name}
                      isMultiPrice={e.have_price}
                      price={e.price}
                      prices={e.sub_price}
                      img={"/subs/thumbnail/"}
                      type="sub"
                      allData={e}
                    />
                  );
                })
              : null}
          </SortableContext>
        </DndContext>

        {subItemView ? (
          <button
            id="addItem"
            className="add-item-container"
            onClick={() => {
              dispatch(
                viewEdit({
                  selectType: "newSubItem",
                  id: id,
                  allData: "",
                })
              );
            }}
          >
            <span className="add-item-container-name">
              <img src={plusSign} alt="+" />
              {t("addItem")}
            </span>
          </button>
        ) : null}
      </section>
    </div>
  );
}
