import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import MainPage from "./MainPage";
import EditMenu from "./pages/EditMenu";
import { useSelector } from "react-redux";
import Payment from "./pages/Payment";
import MissingPage from "./pages/missingPage";

function App() {
  const { screenScroll } = useSelector((store) => store.screenSlice);

  console.log("Scroll : ", screenScroll);

  return (
    <div className="h-100" style={{ overflow: screenScroll ? "" : "hidden" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />}></Route>
          <Route path="/Edit/:title" element={<EditMenu />}></Route>
          <Route path="/payment" element={<Payment />}></Route>
          <Route path="/*" element={<MissingPage />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
