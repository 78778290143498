import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

export const getMenus = createAsyncThunk("userMenus", async (userId) => {
  let url = `https://menuapi.mubatech.com/api/admin/v1/menu/${userId}`;

  let data = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return data.data;
});

export const getMenusSlice = createSlice({
  name: "userMenus",
  initialState: {
    allMenu: [],
    status: false,
    allMenuSuccess: false,
  },

  extraReducers: {
    [getMenus.pending]: (state, action) => {
      state.status = true;
    },
    [getMenus.fulfilled]: (state, action) => {
      state.status = false;
      state.allMenu = action.payload;
      state.allMenuSuccess = true;
    },
  },
});
