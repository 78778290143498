import { useState } from "react";
import Cookies from "universal-cookie";
import { createMenu } from "../../services/menuServices";
import {
  ColorsContainer,
  DataContainer,
  ImageContainer,
  LangsSwitch,
  SocialContainer,
  SubmitData,
  getNormalFont,
} from "./sharedMenuComponents";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();

export default function CreateMenu({ makeMenu, setMakeMenu }) {
  const [image, setImage] = useState();

  const { t } = useTranslation();

  const [data, setData] = useState({
    title: "",
  });
  const [social, setSocial] = useState({
    phone: "",
    address: "",
    youtube: "",
    insta: "",
    snap: "",
    fb: "",
  });
  const [langs, setLangs] = useState({
    en: false,
    ku: false,
    ar: false,
    tr: false,
  });
  const [colors, setColors] = useState({
    primary: "#ffffff",
    secondary: "#ffffff",
    primary_font: "#ffffff",
    secondary_font: "#ffffff",
  });

  function emptyData() {
    setImage();

    setData({
      title: "",
    });
    setSocial({
      phone: "",
      address: "",
      youtube: "",
      insta: "",
      snap: "",
      fb: "",
    });
    setLangs({
      en: false,
      ku: false,
      ar: false,
      tr: false,
    });
    setColors({
      primary: "#ffffff",
      secondary: "#ffffff",
      primary_font: "#ffffff",
      secondary_font: "#ffffff",
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    const formData = new FormData();

    const validData = {
      user_id: cookies.get("userID"),
      image: image,

      title: data.title,

      en: langs.en,
      ku: langs.ku,
      ar: langs.ar,
      tr: langs.tr,

      youtube: social.youtube,
      insta: social.insta,
      snap: social.snap,
      fb: social.fb,
      phone: social.phone,
      address: social.address,

      primary: colors.primary,
      secondary: colors.secondary,
      primary_font: colors.primary_font,
      secondary_font: colors.secondary_font,
      normal_font: getNormalFont(colors.primary),
    };

    Object.keys(validData).forEach((e) => formData.append(e, validData[e]));

    const token = cookies.get("token");

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    createMenu(formData, token);

    // emptyData();
  }

  return !makeMenu ? null : (
    <div className="d-flex fixed-top fixed-bottom bg-opacity-25 bg-dark ">
      <form
        onSubmit={(e) => handleSubmit(e)}
        className="bg-white m-auto my-5 rounded oveflow-hidden d-flex flex-column justify-content-between"
        style={{ maxWidth: "1000px", width: "90%" }}
      >
        <div className="d-flex justify-content-end bg-dark-subtle p-2 rounded-top">
          <button
            type="button"
            className="btn-close bg-light"
            onClick={() => {
              setMakeMenu(false);
              emptyData();
            }}
          ></button>
        </div>
        <div className="overflow-y-auto p-4">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <DataContainer data={data} setData={setData} t={t} />
              <LangsSwitch langs={langs} setLangs={setLangs} t={t} />
            </div>
            <div className="col-md-6 col-sm-12">
              <ImageContainer image={image} setImage={setImage} t={t} />
            </div>
          </div>

          <ColorsContainer colors={colors} setColors={setColors} t={t} />
          <SocialContainer social={social} setSocial={setSocial} t={t} />
        </div>

        <div className="bg-body-secondary p-2 border-top rounded-bottom ">
          <SubmitData setShow={setMakeMenu} emptyData={emptyData} t={t} />
        </div>
      </form>
    </div>
  );
}
