import { useState } from "react";
import { userRegistration } from "../../services/menuServices";
import Cookies from "universal-cookie";
import { t } from "i18next";
const cookies = new Cookies();

export default function Register({ changeHaveAcount }) {
  const [username, setUSername] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [incorect, setIncorect] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const haIssues = incorect || emailError;

  function errorMessage() {
    if (incorect) return "Password does not match !";
    if (emailError) return "Email invalid : Email exists !";
  }

  async function handleRegister(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setIncorect(true);
    } else {
      const formData = new FormData();
      const token = cookies.get("token");
      formData.append("username", username);
      formData.append("email", email);
      formData.append("password", password);

      const newUser = await userRegistration(formData, token);
      if (newUser.message === "this email is invalid") {
        setEmailError(true);
      } else {
        changeHaveAcount();
      }
    }
  }

  return (
    <div className="">
      <h4 className="text-center mb-4">{t("createAccount")}</h4>
      <form onSubmit={handleRegister}>
        <div className="mb-3">
          <label className="form-label" htmlFor="registerEmail">
            {t("emailAddress")}
          </label>
          <input
            className="form-control"
            id="registerEmail"
            aria-describedby="emailHelp"
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            style={{ borderColor: emailError ? "red" : "" }}
            required
          />
          <div id="emailHelp" className="form-text">
            {t("emailHelp")}
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="registerUser">
            {t("username")}
          </label>
          <input
            className="form-control"
            id="registerUser"
            type="text"
            value={username}
            onChange={(e) => setUSername(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="registerPassword">
            {t("password")}
          </label>
          <input
            className="form-control"
            id="registerPassword"
            type="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setIncorect(false);
            }}
            required
            style={{
              borderColor: incorect ? "red" : "",
            }}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="confirmPassword">
            {t("confirmPass")}
          </label>
          <input
            className="form-control"
            id="confirmPassword"
            aria-describedby="confirmHelp"
            type="password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              setIncorect(false);
            }}
            required
            style={{
              borderColor: incorect ? "red" : "",
            }}
          />
          <div className="form-text text-danger" id="confirmHelp">
            {haIssues ? errorMessage() : <div>{"ㅤ"}</div>}
          </div>
        </div>

        <div className="mb-3">
          <button className="btn btn-primary w-100 mb-3">
            {t("register")}
          </button>
        </div>
      </form>
    </div>
  );
}
