import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import {
  DeleteItem,
  isActiveCatagoryApi,
  updateData,
  updateImage,
} from "../../../../services/menuServices";
import {
  showLoader,
  closeLoader,
} from "../../../../redux/updateLoader/updateLoaderSlice";

import {
  ActiveSwitch,
  ChangeRank,
  DeleteContainer,
  Dublicate,
  Move,
  NameContainerEdit,
  NoteContainerEdit,
  SingleImage,
  SubmitContainer,
} from "../sharedComponents";
import { useParams } from "react-router-dom";
import { fetchMenu } from "../../../../redux/menuSlice";
import { closeEdit } from "../../../../redux/menuFunctions/menuFunctionsSlicer";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();
let token = cookies.get("token");

export default function EditCatagory() {
  let [newImage, setNewImage] = useState();
  let [currentTab, setCurrentTab] = useState(0);
  const { t } = useTranslation();

  let dispatch = useDispatch();
  const { title } = useParams();

  let { menu } = useSelector((state) => state.menu);
  let { data } = useSelector((state) => state.menuFunction);
  let languages = menu.langs.map((e) => e.surname);
  let [newData, setNewData] = useState({
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: {
      en: data.note ? data.note.en : "",
      ar: data.note ? data.note.ar : "",
      ku: data.note ? data.note.ku : "",
      tr: data.note ? data.note.tr : "",
    },
  });

  useEffect(() => {
    setNewData({
      name: {
        en: data.name.en,
        ar: data.name.ar,
        ku: data.name.ku,
        tr: data.name.tr,
      },
      note: {
        en: data.note ? data.note.en : "",
        ar: data.note ? data.note.ar : "",
        ku: data.note ? data.note.ku : "",
        tr: data.note ? data.note.tr : "",
      },
    });
    setNewImage();
    setCurrentTab(0);
  }, [data]);

  const oldData = {
    name: {
      en: data.name.en,
      ar: data.name.ar,
      ku: data.name.ku,
      tr: data.name.tr,
    },
    note: {
      en: data.note ? data.note.en : "",
      ar: data.note ? data.note.ar : "",
      ku: data.note ? data.note.ku : "",
      tr: data.note ? data.note.tr : "",
    },
  };

  async function submitData(e) {
    e.preventDefault();
    dispatch(showLoader());
    let formData = new FormData();

    let verifyData = {
      id: data.id,
      en: newData.name.en,
      ar: newData.name.ar,
      ku: newData.name.ku,
      tr: newData.name.tr,

      note_en: data.note ? newData.note.en : "",
      note_ar: data.note ? newData.note.ar : "",
      note_ku: data.note ? newData.note.ku : "",
      note_tr: data.note ? newData.note.tr : "",

      title: data.title,
    };

    Object.keys(verifyData).forEach((objName) => {
      formData.append(objName, verifyData[objName]);
    });
    formData.append("type", "category");

    await updateData(formData, token);
    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  async function submitImage(e) {
    e.preventDefault();
    dispatch(showLoader());

    let imageFormData = new FormData();

    imageFormData.append("image", newImage);
    imageFormData.append("type", "category");
    imageFormData.append("id", data.id);

    await updateImage(imageFormData, token);
    dispatch(closeLoader());

    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  async function handleDelete(e) {
    e.preventDefault();
    dispatch(showLoader());

    await DeleteItem(data.id, "category", token);
    dispatch(closeLoader());
    dispatch(closeEdit());
    dispatch(fetchMenu(title));
  }

  function handleActiveSwitch(e) {
    const token = cookies.get("token");

    isActiveCatagoryApi(data.id, e, token);
  }

  let myTabs = [
    <DataChange
      submitData={submitData}
      oldData={oldData}
      languages={languages}
      newData={newData}
      setNewData={setNewData}
      handleDelete={handleDelete}
      t={t}
    />,
    <ImageChange
      submitImage={submitImage}
      newImage={newImage}
      setNewImage={setNewImage}
      data={data}
      t={t}
    />,
    <ManageTab
      data={data}
      handleDelete={handleDelete}
      handleActiveSwitch={handleActiveSwitch}
      t={t}
    />,
  ];

  return (
    <div className="">
      <ul className="nav bg-white nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab == 0 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(0)}
          >
            {t("data")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab == 1 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(1)}
          >
            {t("image")}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link px-2 ${
              currentTab == 2 ? "bg-dark-subtle" : ""
            }`}
            onClick={() => setCurrentTab(2)}
          >
            {t("edit")}
          </button>
        </li>
      </ul>

      {myTabs[currentTab]}
    </div>
  );
}

function DataChange({
  submitData,
  oldData,
  languages,
  newData,
  setNewData,
  t,
}) {
  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        submitData(e);
      }}
    >
      <NameContainerEdit
        oldData={oldData}
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />

      <hr />
      <NoteContainerEdit
        oldData={oldData}
        languages={languages}
        newData={newData}
        setNewData={setNewData}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
      <hr className="mb-4" />
    </form>
  );
}

function ImageChange({ submitImage, newImage, setNewImage, data, t }) {
  return (
    <form
      className="p-3"
      onSubmit={(e) => {
        submitImage(e);
      }}
    >
      <SingleImage
        newImage={newImage}
        setNewImage={setNewImage}
        id={data.id}
        t={t}
      />
      <hr />
      <SubmitContainer t={t} />
    </form>
  );
}

function ManageTab({ data, handleDelete, handleActiveSwitch, t }) {
  return (
    <div className="p-3">
      <h4> {t("manage")} </h4>
      <hr />

      <ActiveSwitch
        defVal={data.active}
        handleActiveSwitch={handleActiveSwitch}
        t={t}
      />
      <ChangeRank />
      <Move />
      <Dublicate />
      <DeleteContainer handleDelete={handleDelete} type="CATAGORY" t={t} />
    </div>
  );
}
