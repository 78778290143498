import { useEffect, useState } from "react";
import "../css/main.css";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import arrowDown from "../images/arrowDown.svg";
import mubaLogoLight from "../images/mubaLogoLight.jpg";

export default function Auth() {
  const [haveAcount, setHaveAcount] = useState(false);
  const [viewLangList, setViewLangList] = useState(false);
  const { i18n } = useTranslation();

  function changeHaveAcount() {
    setHaveAcount(!haveAcount);
  }

  const languageList = {
    en: { nickName: "English" },
    ar: { nickName: "Arabic" },
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="px-4 py-3 d-flex justify-content-between">
        <h5 className="d-flex align-items-end">
          <img src={mubaLogoLight} alt="mubaLogo" style={{ height: "25px" }} />
          <span className="ms-2 fw-bold">MUBA MENU</span>
        </h5>
        <div
          className="border-0 position-relative"
          onMouseEnter={() => setViewLangList(true)}
          onMouseLeave={() => setViewLangList(false)}
        >
          <p className="text-primary" style={{ cursor: "pointer" }}>
            {t(languageList[i18n.resolvedLanguage].nickName)}
            <img
              className="mx-2"
              src={arrowDown}
              style={{ height: "5px" }}
              alt="o"
            />
          </p>
          <div className="position-absolute end-0 shadow rounded-1 overflow-hidden">
            {viewLangList ? (
              <div className="bg-dark-subtle custom-showing-animation">
                {Object.keys(languageList).map((indLang) => (
                  <button
                    className={`btn w-100 rounded-0 ${
                      indLang === i18n.resolvedLanguage
                        ? "btn-primary"
                        : "btn-light"
                    }`}
                    onClick={() => {
                      i18n.changeLanguage(indLang);
                      setViewLangList(false);
                    }}
                  >
                    {t(languageList[indLang].nickName)}
                  </button>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="h-100 w-100 mx-0 px-1 d-flex justify-content-center">
        <div
          className="m-auto mx-2 py-4 px-4 rounded-3 shadow w-100"
          style={{ maxWidth: "500px" }}
        >
          {!haveAcount ? (
            <Login />
          ) : (
            <Register changeHaveAcount={changeHaveAcount} />
          )}
          <div className="text-center w-100">
            {!haveAcount ? (
              <div className="mt-2">
                <span>{t("noAccount")}</span>
                <strong className="text-danger" onClick={changeHaveAcount}>
                  {" "}
                  {t("registerHere")}
                </strong>
              </div>
            ) : (
              <div className="mt-2">
                <span>{t("yesAccount")}</span>
                <strong className="text-danger" onClick={changeHaveAcount}>
                  {" "}
                  {t("loginHere")}
                </strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
