import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
export default function Payment() {
  const { t } = i18next;
  const headerToolTipRef = useRef();
  const [headerToolTipView, setHeaderToolTipView] = useState(false);

  return (
    <div>
      <div className=" py-3">
        <header>
          <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom container">
            <a
              href="/"
              className="d-flex align-items-center link-body-emphasis text-decoration-none"
            >
              <span className="fs-4">{t("backHome")}</span>
            </a>
            <div className="position-relative my-auto" ref={headerToolTipRef}>
              <button
                className="btn btn-primary my-auto"
                onClick={() => setHeaderToolTipView(!headerToolTipView)}
              >
                {t("settings")}
              </button>
              <HeaderToolTip
                headerToolTipRef={headerToolTipRef}
                setHeaderToolTipView={setHeaderToolTipView}
                headerToolTipView={headerToolTipView}
                t={t}
              />
            </div>
          </div>

          <div className="pricing-header p-3 pb-md-4 mx-auto text-center container">
            <h1 className="display-4 fw-normal text-body-emphasis">
              {t("pricing")}
            </h1>
            <p className="fs-5 text-body-secondary">{t("pickPlan")}</p>
          </div>
        </header>

        <section className="container">
          <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card mb-4 rounded-3 shadow-sm ">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">{t("monthPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    $50
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">‎ </small>
                    </s>
                  </p>
                  <hr />
                  <p className="fs-4 mb-3">{t("total")} - $50 </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-outline-primary"
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="card mb-4 rounded-3 shadow-sm">
                <div className="card-header py-3">
                  <h4 className="my-0 fw-normal">{t("halfYearPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    $40
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">
                        $50
                        {t("mo")}
                      </small>
                    </s>
                  </p>

                  <hr />
                  <p className="fs-4 mb-3">{t("total")} - $240 </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-12">
              <div className="card mb-4 rounded-3 shadow-sm border-primary">
                <div className="card-header py-3 text-bg-primary border-primary">
                  <h4 className="my-0 fw-normal">{t("yearPlan")}</h4>
                </div>
                <div className="card-body">
                  <h1 className="card-title pricing-card-title mb-2">
                    $30
                    <small className="text-body-secondary fw-light">
                      {t("mo")}
                    </small>
                  </h1>
                  <p className="fs-2">
                    <s>
                      <small className="text-muted fw-light">
                        $50
                        {t("mo")}
                      </small>
                    </s>
                  </p>

                  <hr />
                  <p className="fs-4 mb-3">{t("total")} - $360 </p>
                  <button
                    type="button"
                    className="w-100 btn btn-lg btn-primary"
                  >
                    {t("buyPlan")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

function HeaderToolTip({
  headerToolTipRef,
  setHeaderToolTipView,
  headerToolTipView,

  t,
}) {
  const { i18n } = useTranslation();
  const [viewLangList, setViewLangList] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [headerToolTipRef]);

  function handleClickOutside(event) {
    if (
      headerToolTipRef.current &&
      !headerToolTipRef.current.contains(event.target)
    ) {
      setHeaderToolTipView(false);
      setViewLangList(false);
    }
  }

  const languageList = {
    en: { nickName: "English" },
    ar: { nickName: "Arabic" },
  };

  return headerToolTipView ? (
    <div className="position-absolute z-3 end-50 mt-1 d-flex gap-1">
      {viewLangList ? (
        <div
          className="bg-light border border-dark-subtle rounded-2 py-2"
          style={{ height: "min-content" }}
        >
          {Object.keys(languageList).map((indLang) => (
            <button
              className={`btn btn-light btn-sm w-100 px-2 rounded-0 fw-semibold ${
                indLang === i18n.resolvedLanguage
                  ? "text-primary"
                  : "text-dark-emphasis"
              }`}
              onClick={() => {
                i18n.changeLanguage(indLang);
                setHeaderToolTipView(false);
                setViewLangList(false);
              }}
            >
              {t(languageList[indLang].nickName)}
            </button>
          ))}
        </div>
      ) : null}
      <div className="bg-light border border-dark-subtle rounded-2 py-2">
        <button
          className="btn btn-sm btn-light text-nowrap w-100 rounded-0"
          onClick={() => setViewLangList(!viewLangList)}
        >
          {t("changeLang")}
        </button>
      </div>
    </div>
  ) : null;
}
