import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  screenScroll: true,
};

const screenScrollSlicer = createSlice({
  name: "screenScroll",
  initialState,
  reducers: {
    setScrollOn: (state) => {
      state.screenScroll = true;
    },
    setScrollOff: (state) => {
      state.screenScroll = false;
    },
  },
});

export default screenScrollSlicer.reducer;

export const { setScrollOff, setScrollOn } = screenScrollSlicer.actions;
